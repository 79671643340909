
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditProductHsnModal from "@/components/modals/forms/EditProductHsnModal.vue";

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-product-hsn-details",
  components: {
   EditProductHsnModal
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();
    var tax_list: any[] = []
   interface ViewCategories {
      hsn_code,      
      hsn_chapter,
      hsn_heading,
      hsn_description,
      tax_slab,
      active : {
        label :string;
        color :string;
      }
    };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
      hsn_code: "",      
      hsn_chapter: "",
      hsn_heading: "",
      hsn_description: "",
      tax_slab: "",
      active: {
        label: "",
        color: "",
      }
    }

    const getProductHsnDetails = async () => {
     
      try {

      var values = { "hsn_code"  :  props.id, "page"  : 1, "records_per_page" : 1}

      await store.dispatch(Actions.CUST_HSN_LIST, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
        
        for (var tax in data.tax_slab){
          if(data.tax_slab[tax] == 1){
            tax_list.push(tax)
          }
        }

        resultsView.value = {
          hsn_code: data.hsn_code,      
          hsn_chapter: data.hsn_chapter,
          hsn_heading: data.hsn_heading,
          hsn_description: data.hsn_description,
          tax_slab: tax_list,
          active: {
            label: active_label,
            color: active_color
          }, 
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    onMounted( async () => {
      await getProductHsnDetails();
      setCurrentPageBreadcrumbs("Product Hsn Details", ["Product"]);
    });


    return {
      resultsView,
      ids,
    };

  }
});
